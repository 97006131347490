// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';

// application
import {
    Fi24Hours48Svg,
    FiFreeDelivery48Svg,
    FiPaymentSecurity48Svg,
    FiTag48Svg,
    WheelSvg,
    BreakSvg,
    ACSvg,
    
} from '../../svg';

import {
    EngineSvg,
    GearshiftSvg,
    LightsSvg,
    ChassisSvg,
    DashboardSvg,
} from '../../svg';


export default function BlockFeatures(props) {
    const { layout } = props;

    return (
        <div className={`block block-features block-features--layout--${layout}`}>
            <div className="w-75 m-auto">
                <div className="block-features__list">
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <EngineSvg />
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Engines</div>
                        </div>
                    </div>

                    
                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <GearshiftSvg />
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Gearshits</div>
                        </div>
                    </div>

                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                        <BreakSvg />
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Breaks</div>
                        </div>
                    </div>
                    
                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <WheelSvg />
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Wheels</div>
                        </div>
                    </div>

                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <LightsSvg />
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Lights</div>
                        </div>
                    </div>

                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <ChassisSvg />
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Cars</div>
                        </div>
                    </div>

                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <ACSvg />
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Modules</div>
                        </div>
                    </div>

                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <DashboardSvg />
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Dashboard</div>
                        </div>
                    </div>

                    {/* <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <DashboardSvg />
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Dashboard</div>
                        </div>
                    </div>

                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <DashboardSvg />
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Dashboard</div>
                        </div>
                    </div>

                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <DashboardSvg />
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Dashboard</div>
                        </div>
                    </div>

                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <DashboardSvg />
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Dashboard</div>
                        </div>
                    </div> */}
                    

                </div>
            </div>
        </div>
    );
}

BlockFeatures.propTypes = {
    layout: PropTypes.oneOf(['classic', 'boxed']),
};

BlockFeatures.defaultProps = {
    layout: 'classic',
};
