// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// application
import NavPanel from './NavPanel';
import Search from './Search';
//import Topbar from './Topbar';
//import { LogoSvg } from '../../svg';
import TopMenu from './TopMenu';


function Header(props) {
    const { layout } = props;
    let bannerSection;

    if (layout === 'default') {
        bannerSection = (
            <div className="site-header__middle w-75 mx-auto">
                <div className="site-header__logo">
                    <Link to="/"><img src="images/banners/offer.gif" alt="" /></Link>
                </div>
                <div className="site-header__search">
                    <Search context="header" />
                </div>
                <div className="site-header__phone">
                    <div className="site-header__phone-title">
                        <FormattedMessage id="header.phoneLabel" defaultMessage="Sales Hotline" />
                    </div>
                    <div className="site-header__phone-number">
                    <a href="tel:+(65) 6368 1686"><FormattedMessage id="header.message" defaultMessage="+(65) 6368 1686" /></a>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="site-header">
            {/* <Topbar /> */}
            <div className="site-header__nav-panel">
                <NavPanel layout={layout} />
            </div>
            {bannerSection}
            <div className="site-header__nav-panel">
                <TopMenu layout={layout} />
            </div>
        </div>
    );
}

Header.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(['default', 'compact']),
};

Header.defaultProps = {
    layout: 'default',
};

export default Header;
