export default [
    {
        id: 1,
        name: 'BMW F46 216D GRAN TOURER 2015 AC CLIMATE CONTROL PANEL SWITCH 9371459',
        price: 512,
        compareAtPrice: null,
        images: ['images/products/p1/1.jpg', 'images/products/p1/2.jpg','images/products/p1/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '9371459' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'F46' },
            { name: 'Specification', value: '216D GRAN TOURER' },
            { name: 'Year', value: '2015' },
            { name: 'SKU', value: '136938' },
            { name: 'Other Part Number', value: '20020110' },
            { name: 'Type', value: 'Switch' },
            { name: 'Surface Finish', value: 'Plastic' },
            { name: 'Color', value: 'Black' },
        ],
        options: [],
    },
    {
        id: 2,
        name: 'BMW E60 LCI 523I XL 2007 AUTOMATIC GEAR SELECTOR SHIFTER KNOB 9159751',
        price: 50,
        compareAtPrice: null,
        images: ['images/products/p2/1.jpg', 'images/products/p2/2.jpg','images/products/p2/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '9159751' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: 'E60 LCI' },
            { name: 'Specification', value: '523I XL' },
            { name: 'Year', value: '2007' },
            { name: 'SKU', value: '115118' },
            { name: 'Other Part Number', value: '033810505' },
            { name: 'Type', value: 'Knob' },
            { name: 'Transmission Type', value: 'Automatic' },
        ],
        options: [],
    },
    {
        id: 3,
        name: 'JAGUAR XJ 2011 INTERIOR CENTER DASHBOARD CLOCK AW93-1500-AF 4031600',
        price: 161,
        compareAtPrice: null,
        images: ['images/products/p3/1.jpg', 'images/products/p3/2.jpg','images/products/p3/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'AW93-1500-AF' },
            { name: 'Brand', value: 'Jaguar' },
            { name: 'Model', value: 'XJ' },
            { name: 'Other Part Number', value: '4031600' },
            { name: 'SKU', value: '121464' },
            { name: 'Year', value: '2011' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Placement on Vehicle', value: 'Center' },
            { name: 'Type', value: 'Clock' },
        ],
        options: [],
    },
    {
        id: 4,
        name: 'PORSCHE CARRERA 911 996 1999 INSTRUMENT CLUSTER SPEEDOMETER LHD',
        price: 633,
        compareAtPrice: null,
        images: ['images/products/p4/1.jpg', 'images/products/p4/2.jpg','images/products/p4/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Porsche' },
            { name: 'Model', value: 'CARRERA 911 996' },
            { name: 'SKU', value: '109676' },
            { name: 'Year', value: '1999' },
            { name: 'Surface Finish', value: 'Plastic' },
            { name: 'Type', value: 'Speedometer' },
        ],
        options: [],
    },
    {
        id: 5,
        name: 'MERCEDES BENZ W201 2006 BOSCH ENGINE ECU CONTROL UNIT 0105455632 0280800388',
        price: 115,
        compareAtPrice: null,
        images: ['images/products/p5/1.jpg', 'images/products/p5/2.jpg','images/products/p5/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '0105455632' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W201' },
            { name: 'Year', value: '2006' },
            { name: 'Bosch Part number', value: '0280800388' },
            { name: 'Country of Manufacture', value: 'Germany' },
            { name: 'Surface Finish', value: 'Metal' },
            { name: 'Type', value: 'ECU' },
        ],
        options: [],
    },
    {
        id: 6,
        name: 'MERCEDES BENZ W203 C180 DASH CENTER AC LOUVER VENT BLACK A2038302254',
        price: 75,
        compareAtPrice: null,
        images: ['images/products/p6/1.jpg', 'images/products/p6/2.jpg','images/products/p6/3.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'A2038302254' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W203' },
            { name: 'Specification', value: 'C180' },
            { name: 'SKU', value: '153971' },
            { name: 'Surface Finish', value: 'Plastic' },
            { name: 'Type', value: 'AC Louver Vent' },
            { name: 'Color', value: 'Black' },
        ],
        options: [],
    },
    {
        id: 7,
        name: 'MERCEDES BENZ W221 S350 2007 REAR CENTER CONSOLE AIR VENT SET 2218301054',
        price: 128,
        compareAtPrice: null,
        images: ['images/products/p7/1.jpg', 'images/products/p7/2.jpg','images/products/p7/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '2218301054' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W221' },
            { name: 'Specification', value: 'S350' },
            { name: 'Year', value: '2007' },
            { name: 'Other Part Number', value: '2216801639' },
            { name: 'SKU', value: '109023' },
            { name: 'Placement on Vehicle', value: 'Rear Center' },
            { name: 'Type', value: 'Console Air Vent' },
        ],
        options: [],
    },
    {
        id: 8,
        name: 'PORSCHE 911 2007 BOSE AUDIO RADIO AMP AMPLIFIER 99764533422 434011',
        price: 296,
        compareAtPrice: null,
        images: ['images/products/p8/1.jpg', 'images/products/p8/2.jpg','images/products/p8/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '99764533422' },
            { name: 'Brand', value: 'Porsche' },
            { name: 'Model', value: '911' },
            { name: 'Year', value: '2007' },
            { name: 'Other Part Number', value: '434011' },
            { name: 'SKU', value: '123188' },
            { name: 'Surface Finish', value: 'Metal' },
            { name: 'Country of Manufacture', value: 'United States' },
            { name: 'Type', value: 'Amplifier' },
        ],
        options: [],
    },
    {
        id: 9,
        name: 'PORSCHE 911 1999 EXTERIOR DOOR HANDLE FRONT RIGHT 996537064',
        price: 43,
        compareAtPrice: null,
        images: ['images/products/p9/1.jpg', 'images/products/p9/2.jpg','images/products/p9/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '996537064' },
            { name: 'Brand', value: 'Porsche' },
            { name: 'Model', value: '911' },
            { name: 'Year', value: '1999' },
            { name: 'Placement on Vehicle', value: 'Front Right' },
            { name: 'Type', value: 'Door Handle' },
        ],
        options: [],
    },
    {
        id: 10,
        name: "BMW 1' 2' 3' 4' SERIES FRONT RIGHT FOG LAMP DRIVING LIGHT 7248912",
        price: 109,
        compareAtPrice: null,
        images: ['images/products/p10/1.jpg', 'images/products/p10/2.jpg','images/products/p10/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '7248912' },
            { name: 'Brand', value: 'BMW' },
            { name: 'Model', value: "1' 2' 3' 4' SERIES" },
            { name: 'Placement on Vehicle', value: 'Front Right' },
            { name: 'Type', value: 'Fog Lamp' },
        ],
        options: [],
    },
    {
        id: 11,
        name: 'MERCEDES BENZ W222 S320 2017 FRONT BUMPER',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p11/1.jpg', 'images/products/p11/2.jpg','images/products/p11/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'A2228800147' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S320' },
            { name: 'Year', value: '2017' },
            { name: 'Other Part Number', value: 'A2228800247' },
            { name: 'Type', value: 'Front Bumper' },
        ],
        options: [],
    },
    {
        id: 12,
        name: 'MERCEDES BENZ W222 S320 2017 REAR BUMPER',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p12/1.jpg', 'images/products/p12/2.jpg','images/products/p12/3.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'A2228800040' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S320' },
            { name: 'Year', value: '2017' },
            { name: 'Other Part Number', value: 'A2228800049' },
            { name: 'Type', value: 'Rear Bumper' },
        ],
        options: [],
    },
    {
        id: 13,
        name: 'MERCEDES BENZ W222 S320 2017 BOOT LID',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p13/1.jpg', 'images/products/p13/2.jpg','images/products/p13/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S320' },
            { name: 'Year', value: '2017' },
            { name: 'Type', value: 'Boot Lid' },
        ],
        options: [],
    },
    {
        id: 14,
        name: 'MERCEDES BENZ W222 S320 2017 LED HEADLAMP LEFT & RIGHT',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p14/1.jpg', 'images/products/p14/2.jpg','images/products/p14/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'A2229060802' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S320' },
            { name: 'Year', value: '2017' },
            { name: 'Other Part Number', value: 'A2229060702' },
            { name: 'Type', value: 'Headlamp' },
        ],
        options: [],
    },
    {
        id: 15,
        name: 'MERCEDES BENZ W251 R400 4MATIC 2013 LED TAIL LAMP RIGHT & LEFT (#467-42X1)',
        price: 680,
        compareAtPrice: null,
        images: ['images/products/p15/1.jpg', 'images/products/p15/2.jpg','images/products/p15/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'A2518202064' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W251' },
            { name: 'Specification', value: 'R400 4MATIC' },
            { name: 'Year', value: '2013' },
            { name: 'Other Part Number', value: 'A2518201964' },
            { name: 'Type', value: 'Tail Lamp' },
        ],
        options: [],
    },
    {
        id: 16,
        name: 'MERCEDES BENZ W172 SLK200 SIDE MIRRORS RIGHT & LEFT',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p16/1.jpg', 'images/products/p16/2.jpg','images/products/p16/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W172' },
            { name: 'Specification', value: 'SLK200' },
            { name: 'Type', value: 'Side Mirror' },
        ],
        options: [],
    },
    {
        id: 17,
        name: 'MERCEDES BENZ W292 GLE400 4MATIC 2017 FENDERS RIGHT & LEFT',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p17/1.jpg', 'images/products/p17/2.jpg','images/products/p17/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W292' },
            { name: 'Specification', value: 'GLE400 4MATIC' },
            { name: 'Year', value: '2017' },
            { name: 'Type', value: 'Fender' },
        ],
        options: [],
    },
    {
        id: 18,
        name: 'MERCEDES BENZ W172 SLK200 BONNET RED',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p18/1.jpg', 'images/products/p18/2.jpg','images/products/p18/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W172' },
            { name: 'Specification', value: 'SLK200' },
            { name: 'Type', value: 'Bonnet' },
        ],
        options: [],
    },
    {
        id: 19,
        name: 'MERCEDES BENZ W172 SLK200 SIDE SKIRTS RIGHT & LEFT',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p19/1.jpg', 'images/products/p19/2.jpg','images/products/p19/3.jpg'],
        badges: ['sale'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W172' },
            { name: 'Specification', value: 'SLK200' },
            { name: 'Type', value: 'Side Skirt' },
        ],
        options: [],
    },
    {
        id: 20,
        name: 'MERCEDES BENZ W292 GLE400 4MATIC 2017 REAR LEFT DOOR',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p20/1.jpg', 'images/products/p20/2.jpg','images/products/p20/3.jpg'],
        badges: ['DEAL'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W292' },
            { name: 'Specification', value: 'GLE400 4MATIC' },
            { name: 'Year', value: '2017' },
            { name: 'Type', value: 'Door' },
        ],
        options: [],
    },
    {
        id: 21,
        name: 'PORSCHE MACAN S 3.0L V6 2015 LONG BLOCK MOTOR ENGINE CTM025020 FREE SHIPPING',
        price: 14998,
        compareAtPrice: null,
        images: ['images/products/p21/1.jpg', 'images/products/p21/2.jpg','images/products/p21/3.jpg'],
        badges: ['new'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'CTM025020' },
            { name: 'Brand', value: 'Porsche' },
            { name: 'Model', value: 'MACAN S' },
            { name: 'Specification', value: '3.0L' },
            { name: 'Year', value: '2015' },
            { name: 'Fuel Type', value: 'Gasoline' },
            { name: 'SKU', value: '154162' },
            { name: 'Performance Type', value: 'Turbocharged' },
            { name: 'Type', value: 'Complete Engine' },
        ],
        options: [],
    },
    {
        id: 22,
        name: 'MERCEDES BENZ W222 S400 2017 STAGGERED RIMS WITH TYRES (19)',
        price: 1800,
        compareAtPrice: null,
        images: ['images/products/p22/1.jpg', 'images/products/p22/2.jpg','images/products/p22/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S400' },
            { name: 'Year', value: '2017' },
            { name: 'Type', value: 'WheelS With Tyre' },
        ],
        options: [],
    },
    {
        id: 23,
        name: 'MERCEDES BENZ W222 S400 GEARBOX (722904) & TORQUE A2312500802',
        price: 99999,
        compareAtPrice: null,
        images: ['images/products/p23/1.jpg', 'images/products/p23/2.jpg','images/products/p23/3.jpg'],
        badges: ['hot'],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: 'A2312500802' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S400' },
            { name: 'Other Part Number', value: '722904' },
            { name: 'Type', value: 'Gearbox Torque' },
        ],
        options: [],
    },
    {
        id: 24,
        name: 'MERCEDES BENZ W222 S400 2016 AIR ABSORBER SUSPENSION FRONT & REAR',
        price: 1391,
        compareAtPrice: null,
        images: ['images/products/p24/1.jpg', 'images/products/p24/2.jpg','images/products/p24/3.jpg'],
        badges: [''],
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        features: [
            { name: 'Part Number', value: '7248912' },
            { name: 'Brand', value: 'Mercedes-Benz' },
            { name: 'Model', value: 'W222' },
            { name: 'Specification', value: 'S400' },
            { name: 'Year', value: '2016' },
            { name: 'Type', value: 'Absorber' },
        ],
        options: [],
    },
];