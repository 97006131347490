export default {
    name: 'Propel Autoparts',
    fullName: 'Propel Auto Parts - Reuse with Quality',
    url: 'https://propel-auto.com/',
    author: {
        name: 'Propel',
        profile_url: 'https://propel-auto.com/',
    },
    contacts: {
        address: 'No. 28, Kranji Road,Singapore, 739467',
        email: 'sales@propel-auto.com',
        phone: '+(65) 6368 1686',
        malaysia :{
            address: 'PTD 103234, (HSD 58397), Jalan Lapangan Terbang, Mukim Senai,Daerah Kulaijaya, Johor Darul Tazim,West Malaysia',
            email: 'ng@propel-auto.com',
            phone: '+(60)7 5581 885, +(60)7 - 5581 886',
        }
    },
    social:{
        facebook:'https://www.facebook.com/Propelauto/',
        twitter:'https://twitter.com/propelauto',
        youtube:'#',
        instagram:'https://www.instagram.com/propelautoparts/',
    }
};
