// react
import React from 'react';

// data stubs
import specification from '../../data/shopProductSpec';


function ProductTabSpecification(props) {

    const {
        product,
        layout,
        quickviewOpen,
        cartAddItem,
        wishlistAddItem,
        compareAddItem,
    } = props;
    let features;

    const sections = specification.map((section, index) => {
        const features = section.features.map((feature, index) => (
            <div key={index} className="spec__row">
                <div className="spec__name">{feature.name}</div>
                <div className="spec__value">{feature.value}</div>
            </div>
        ));

        return (
            <div key={index} className="spec__section">
                <h4 className="spec__section-title">{section.name}</h4>
                {features}
            </div>
        );
    });

    
    // if (product.features && product.features.length) {
    //     features = (
    //         <ul className="product-card__features-list">
    //             {product.features.map((feature, index) => (
    //                 <li key={index}>{`${feature.name}: ${feature.value}`}</li>
    //                 // <div key={index} className="spec__row">
    //                 //     <div className="spec__name">{feature.name}</div>
    //                 //     <div className="spec__value">{feature.value}</div>
    //                 // </div>

    //             ))}
    //         </ul>
    //     );
    // }

    return (
        <div className="spec">
            <h3 className="spec__header">Specification</h3>
            {/* {sections} */}
            <div className="spec__disclaimer">
                Nunc sollicitudin, nunc id accumsan semper, libero nunc aliquet nulla, nec pretium
                ipsum risus ac neque. Morbi eu facilisis purus. Quisque mi tortor, cursus in nulla
                ut, laoreet commodo quam. Pellentesque et ornare sapien. In ac est tempus urna
                tincidunt finibus. Integer erat
            </div>
        </div>
    );
}

export default ProductTabSpecification;
